<template>
  <div
    id="modal-delete-confirm"
    uk-modal
    esc-close="false"
    bg-close="false"
    container="#container-modal-detail-user"
  >
    <div class="uk-modal-dialog uk-modal-body">
      <div class="uk-modal-title uk-text-danger">
        <img
          v-lazy="`${images}/icon/warning.svg`"
          alt=""
          class="uk-margin-small-right"
        >
        Hapus User
      </div>
      <p style="font-size:15px">
        Apakah kamu yakin ingin menghapus <br> "{{ modalDelete.name }}" ?
      </p>
      <div style="padding-top:20px">
        <button
          class="uk-button uk-button-default"
          type="button"
          :disabled="is_loading"
          @click="handleDeleteEmployee"
        >
          Ya, hapus
        </button>
        <button
          class="uk-button uk-button-primary uk-margin-left uk-modal-close"
          type="button"
          :disabled="is_loading"
        >
          Tidak
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { PREFIX_IMAGE } from '@/utils/constant'

export default {
  data() {
    return {
      is_loading: false,
      images: PREFIX_IMAGE
    }
  },
  computed : {
    ...mapGetters({
      modalDelete: 'user/modalDelete'
    })
  },
  methods: {
    ...mapActions({
      deleteUser: 'user/deleteUser'
    }),
    async handleDeleteEmployee () {
      try {
        this.is_loading = true
        await this.deleteUser(this.modalDelete.id)
        setTimeout(() => {
          window.location = '/admin/user'
        }, 1000)
      } catch (error) {
        this.is_loading = false
      }
    }
  }
}
</script>
