<template>
  <div class="uk-container uk-container-expand uk-margin-top card-scrollable">
    <div uk-grid>
      <div class="uk-width-1-2@s font-header">
        Detail "{{ chick.name }}"
      </div>
      <div class="uk-width-1-2@s">
        <bread-crumb :bread-crumb="breadcrumb" />
      </div>
    </div>

    <container :data-chick="chick" />
    <div id="container-modal-detail-user" />
    <modal-delete-confirm />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Container from './Container'
import ModalDeleteConfirm from './ModalDeleteConfirm'
import BreadCrumb from '@/components/globals/breadcrumb'

export default {
  components: {
    Container,
    ModalDeleteConfirm,
    BreadCrumb
  },
  data() {
    return {
      chick: {},
      breadcrumb: [
        {
          link: '/admin/jenis-ayam',
          title: 'Jenis Ayam'
        },
        {
          link: null,
          title: 'Detail'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      chickenCategoryDetail: 'chickenCategory/chickenCategoryDetail'
    })
  },
  async mounted() {
    await this.getChickenCategoryDetail(this.$route.params.id)
    this.chick = this.chickenCategoryDetail.data
  },
  methods: {
    ...mapActions({
      getChickenCategoryDetail: 'chickenCategory/getChickenCategoryDetail'
    })
  }
}
</script>
