<template>
  <div class="uk-margin-top">
    <div class="uk-card uk-card-default kandang-card">
      <div class="uk-grid">
        <div class="uk-width-1-2">
          <div class="uk-grid uk-grid-small">
            <div class="uk-width-2-5">
              Jenis Ayam
            </div>
            <div class="uk-width-3-5">
              <input
                class="uk-input"
                :value="dataChick.name ? dataChick.name : '-'"
                type="text"
                disabled
              >
            </div>
          </div>
          <div class="uk-grid uk-grid-small">
            <div class="uk-width-2-5">
              Catatan
            </div>
            <div class="uk-width-3-5">
              <textarea
                id=""
                name=""
                class="uk-textarea"
                cols="40"
                rows="5"
                :value="dataChick.note ? dataChick.note : '-'"
                disabled
              />
            </div>
          </div>
        </div>
        <div class="uk-width-1-2">
          <div class="uk-grid uk-grid-small">
            <div class="uk-width-2-5">
              Terakhir Diubah
            </div>
            <div class="uk-width-3-5">
              <input
                class="uk-input"
                :value="dataChick.updated_at ? formatDate(dataChick.updated_at) : '-'"
                type="text"
                disabled
              >
            </div>
          </div>
          <div class="uk-grid uk-grid-small">
            <div class="uk-width-2-5">
              Diubah Oleh
            </div>
            <div class="uk-width-3-5">
              <input
                class="uk-input"
                :value="dataChick.updated_by ? dataChick.updated_by : '-'"
                type="text"
                disabled
              >
            </div>
          </div>
          <div class="uk-grid uk-grid-small">
            <div class="uk-width-2-5">
              Status
            </div>
            <div class="uk-width-3-5">
              <select
                v-model="dataChick.status"
                class="uk-input uk-select" 
                disabled
              >
                <option value="true">
                  Aktif
                </option>
                <option value="false">
                  Tidak Aktif
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="uk-grid uk-grid uk-margin-top">
        <div class="uk-width-1-2">
          <button
            class="uk-button uk-button-default"
            type="button"
            @click="goToBack"
          >
            <img
              v-lazy="`${images}/icon/back.svg`"
              alt=""
              class="uk-margin-small-right"
            >
            Kembali
          </button>
        </div>
        <!-- <div class="uk-width-1-2 uk-text-right">
          <button class="uk-button uk-button-danger" type="button" @click="showDeleteConfirmModal">Hapus</button>
          <button class="uk-button uk-button-primary uk-margin-left" type="button" @click="goToEdit">
            <img v-lazy="`${images}/icon/save.svg`" alt="" class="uk-margin-small-right">
            Ubah
          </button>
        </div> -->
      </div>
    </div>
  </div>
</template>
<script>
import { PREFIX_IMAGE } from '@/utils/constant'
import { dateUtcParanjeString } from '@/utils/formater'

export default {
  components: {
  },
  props: {
    dataChick: {
      required: true,
      type: Object
    }
  },
  data() {
    return {
      images: PREFIX_IMAGE
    }
  },
  computed: {
  },
  mounted() {
  },
  methods: {
    formatDate(date) {
      return dateUtcParanjeString(date)
    },
    goToBack() {
      window.location.href = '/admin/jenis-ayam'
    },
    goToEdit() {
      this.$router.push(`/admin/user/edit/${this.dataChick.id}`)
    },
    showDeleteConfirmModal() {
      window.UIkit.modal('#modal-delete-confirm').show()
      this.setModalDelete(this.dataChick)
    }
  }
}
</script>
